import * as React from 'react'
import * as Base from 'react-festival/build/guest/home'
import { inject } from 'mobx-react'

export class Home extends Base.Home {

    protected get extensions (): string[] {
        return ['ext', '/tag']
    }
}

export default inject('routeManager', 'pageConfigurationStore')(Home)