import * as React from 'react'
import { List } from 'react-festival/build/tag/list'
import { inject } from 'mobx-react'
import { Badge } from 'reactstrap'
import { Tag } from '@festival/tag/store'
import { TagGroup } from '@festival/entities/tag-group'
import cn from 'classnames'

export class TagList extends List {

    getItemClass (tag: Tag, group: TagGroup): string {
        return cn('tag', 'tag-group', 'tag-group-'+group.color, 'mb-2', 'badge', 'badge-pill', 'w-100')
    }

    getItemWrapperClass (tag: Tag, group: TagGroup): string {
        return cn('tag-wrapper-pill', 'mx-0', 'd-inline-block', 'px-1')
    }
}

export default inject('tagStore')(TagList)
