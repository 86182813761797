import * as React from 'react'
import * as Base from 'react-festival/build/guest/show'
import { inject, observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { RouteManager } from '@festival/route-manager'
import { withRouter } from 'react-router-dom'
import { GuestComplete } from '@festival/guest/store'
import { Activity } from '@festival/activity/store'
import ImageMedia from '@festival/component/image-media'
import TagList from './tag-list'
import ActivityLink from '@festival/guest/activity-link'
import { Button, Row, Col } from 'reactstrap'
import DayList from './day-list'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import VersionToggler from '@festival/versionable/version-toggler'
import { PageConfigurationComplete } from '@festival/page-configuration/store'
import { PageGuests } from '@festival/entities/page-configuration'

export class Show extends Base.Show {

    protected renderContent (guest: GuestComplete, participations: Activity[], routeManager: RouteManager, configuration: PageConfigurationComplete<PageGuests>): React.ReactNode {

        const title = guest.active.name + (configuration.details ? ' - ' + configuration.details.showTitle : '')

        return (
            <>
                <Row>
                    <Col xs={12} lg={9}>
                    <VersionToggler container={guest} className="position-absolute" style={{ top: 0 }}/>
                        <ImageMedia media={guest.active.media} name="cover" version="large" className="border border-primary" alt={guest.active.altCover}/>
                        <h3 className="text-primary d-flex justify-content-between mt-2">
                            <span className="text-uppercase">
                                { guest.active.guestOfHonor ? (
                                    <i className="mdi mdi-star"></i>
                                ) : (
                                    <i className="mdi mdi-pound"></i>
                                )}
                                { guest.active.name }
                            </span>

                            <DayList
                                className="d-flex"
                                type="guest"
                                codes={guest.active.tags}
                                root={routeManager.generate('guests')}
                                groups={['day']}
                            />
                        </h3>

                        <div
                            className="biography text-primary"
                            dangerouslySetInnerHTML={{ __html: guest.active.biography }}
                            ></div>
                    </Col>
                    <Col xs={12} lg={3}>
                        <TagList
                            type="guest"
                            codes={guest.active.tags}
                            root={routeManager.generate('guests')}
                            excludeGroupCodes={['day']}
                            />
                        { guest.active.externalLink &&(
                            <>
                                <a
                                    href={guest.active.externalLink}
                                    className="btn btn-danger btn-block btn-sm rounded-pill"
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <i className="mdi mdi-web mr-2"></i>
                                    <FormattedMessage id="guest.show.external_link" />
                                </a>
                            </>
                        )}

                        <div className="social-share mt-4">
                            <h6 className="text-uppercase text-primary text-center mt-2">
                                <FormattedMessage id="guest.show.social-share" />
                            </h6>

                            <div className="d-flex justify-content-around flex-lg-column justify-content-sm-start">
                                <Button
                                    tag={TwitterShareButton}
                                    resetButtonStyle={false}
                                    url={(this.props.routeManager ? this.props.routeManager.root : '') + this.props.location.pathname}
                                    size="sm"
                                    className="btn-twitter text-nowrap mr-2 mr-lg-0 mb-lg-2"
                                    title={title}
                                >
                                    <i className="mdi mdi-twitter mr-2"></i>
                                    <FormattedMessage id="guest.show.social-share.twitter" />
                                </Button>
                                <Button
                                    tag={FacebookShareButton}
                                    resetButtonStyle={false}
                                    url={(this.props.routeManager ? this.props.routeManager.root : '') + this.props.location.pathname}
                                    size="sm"
                                    className="btn-facebook text-nowrap"
                                    quote={title}
                                >
                                    <i className="mdi mdi-facebook mr-2"></i>
                                    <FormattedMessage id="guest.show.social-share.facebook" />
                                </Button>
                            </div>
                        </div>

                        { participations.length > 0 && (
                            <div className="guest-participations mt-4">
                                <h5 className="text-uppercase text-primary text-center mt-2">
                                    <FormattedMessage id="guest.show.participations" />
                                </h5>
                                <Row>
                                    {participations.map((activity: Activity, index: number) => (
                                        <ActivityLink activity={activity} key={index} />
                                    ))}
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default withRouter(inject('routeManager', 'guestStore', 'activityStore', 'pageConfigurationStore')(Show))
