import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { RouteManager } from '@festival/route-manager'
import { Guest } from '@festival/guest/store'
import ImageMedia from '@festival/component/image-media'
import {
    Card, CardBody, CardTitle
} from 'reactstrap'
import TagList from '@festival/tag/list'
import VersionToggler from '@festival/versionable/version-toggler'

export interface Props {
    guest: Guest
    routeManager?: RouteManager
}

export interface State {

}

export class ListItem extends React.Component<Props, State> {
    render (): React.ReactNode {
        const { guest, routeManager } = this.props

        if (!routeManager) {
            return null
        }

        return (
            <Card className="border-0">
                <VersionToggler container={guest} className="position-absolute" style={{ top: 0 }}/>
                <Link to={routeManager.generate('guest-show', {params: {slug: guest.active.slug}})}>
                    <ImageMedia media={guest.active.media} name="sticker" version="large" className="border border-primary" alt={guest.active.altSticker}/>
                </Link>
                <CardBody className="p-1">
                    <CardTitle tag="h6" className="text-uppercase mb-0 text-truncate">
                        <Link
                            to={routeManager.generate('guest-show', {params: {slug: guest.active.slug}})}
                            className=" text-primary"
                            >
                                { guest.active.guestOfHonor ? (
                                <i className="mdi mdi-star"></i>
                            ) : (
                                <i className="mdi mdi-pound"></i>
                            )}
                            { guest.active.name }
                        </Link>
                    </CardTitle>
                    <TagList
                        type="guest"
                        codes={guest.active.tags}
                        root={routeManager.generate('guests')}
                        excludeGroupCodes={['day']}
                        />
                </CardBody>
            </Card>
        )
    }
}

export default inject('routeManager')(observer(ListItem))