import * as React from 'react'
import { inject } from 'mobx-react'
import * as Base from 'react-festival/build/guest/list-content'
import { CardColumns } from 'reactstrap'
import { Guest } from '@festival/guest/store'
import ListItem from '@festival/guest/list-item'

export class ListContent extends Base.ListContent {

    protected get tagBoxComplete(): boolean {
        return true
    }

    protected get rowClass (): string {
        return 'row-cols-2 row-cols-md-3 row-cols-xxl-4'
    }
}

export default inject('guestStore')(ListContent)
