import * as React from 'react'
import * as Base from 'react-festival/build/guest/activity-link'
import { inject } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { RouteManager } from '@festival/route-manager'
import ImageMedia from '@festival/component/image-media'
import { Activity } from '@festival/activity/store'

import {
    Col, Card, CardBody, CardTitle
} from 'reactstrap'


export class ActivityLink extends Base.ActivityLink {
    render (): React.ReactNode {
        const { activity, routeManager } = this.props

        if (!routeManager) {
            return null
        }

        return (
            <Col lg={12} sm={4} xs={6}>
                <Card className="border-0">
                    <Link to={routeManager.generate('activity-show', {params: {slug: activity.active.slug}})}>
                        <ImageMedia media={activity.active.media} name="sticker" version="large" className="border border-primary" alt={activity.active.altSticker}/>
                    </Link>
                    <CardBody className="p-1">
                        <Link to={routeManager.generate('activity-show', {params: {slug: activity.active.slug}})}>
                            <CardTitle tag="h6" className="text-primary text-truncate">{ activity.active.title }</CardTitle>
                        </Link>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default inject('routeManager')(ActivityLink)